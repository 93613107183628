import axios from "axios";

export const validateInterviewLogic = async (
  interviewId: string
): Promise<string[]> => {
  const errors: string[] = [];

  try {
    // Fetch all required data in parallel
    const [
      interviewResponse,
      screenDetailsResponse,
      variablesResponse,
      partiesResponse,
      templatesResponse,
    ] = await Promise.all([
      axios.get(
        `https://skaffolding.com/interviews/api/interviewtypes/${interviewId}/`
      ),
      axios.get(
        `https://skaffolding.com/interviews/api/interviewtypes/${interviewId}/screens/`
      ),
      axios.post("https://skaffolding.com/interviews/api/get_all_variables/", {
        interview_type_id: interviewId,
      }),
      axios.get(
        `https://skaffolding.com/interviews/api/interviewtypes/${interviewId}/roles/`
      ),
      axios.get(
        `https://skaffolding.com/templates_files/api/template-documents/${interviewId}/`
      ),
    ]);

    const interviewData = {
      logic: interviewResponse.data.logic,
      screens: screenDetailsResponse.data,
    };
    const variables = variablesResponse.data?.all_variables || [];
    const parties =
      partiesResponse.data?.map(
        (party: { role_name: any }) => party.role_name
      ) || [];
    const templates = templatesResponse.data || [];

    const validateLogic = (logic: string, screenName?: string): number => {
      const lexerErrors = lintLogics(
        logic || "",
        variables,
        interviewData.screens,
        parties,
        screenName
      );
      return lexerErrors.length || 0;
    };

    const interviewLogicErrors = validateLogic(interviewData.logic);
    if (interviewLogicErrors > 0) {
      errors.push(`Errors in Interview Logic`);
    }

    if (interviewData.screens && Array.isArray(interviewData.screens)) {
      interviewData.screens.forEach((screen: any) => {
        console.log(screen);
        const screenErrors = validateLogic(screen.logic, screen.screen_name);
        if (screenErrors > 0) {
          errors.push(`Errors in screen: ${screen.screen_name}`);
        }
      });
    }

    if (variables && Array.isArray(variables)) {
      variables.forEach((variable: any) => {
        if (
          variable.variable_type === "instruction" &&
          variable.instructionLogic
        ) {
          const logicErrors = validateLogic(variable.instructionLogic);
          if (logicErrors > 0) {
            errors.push(
              `Errors in instruction logic for variable: ${variable.variable_name}`
            );
          }
        }
      });
    }

    if (templates.length > 0 && variables.length > 0) {
      const variableNames = variables.map(
        (variable: { variable_name: string }) =>
          variable.variable_name.toLowerCase()
      );
      const screenNames = interviewData.screens.map(
        (screen: { screen_name: string }) => screen.screen_name.toLowerCase()
      );
      const combinedNames = [...variableNames, ...screenNames, "utils"];
      const missingVariables: string[] = [];

      templates.forEach((template: any) => {
        const { template_variables } = template;
        if (!template_variables.length) return;
        template_variables.forEach((variable: string) => {
          if (!combinedNames.includes(variable.toLowerCase())) {
            missingVariables.push(variable);
          }
        });
      });

      if (missingVariables.length > 0) {
        errors.push(
          `The following variables are in the template but not in the interview: ${missingVariables.join(
            ", "
          )}`
        );
      }
    }
  } catch (error) {
    errors.push("Failed to validate logic due to data fetch error");
    console.error("Validation error:", error);
  }

  return errors;
};
